import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const Web = ({ data, location }) => (
  <Layout>
    <SEO 
      pagetitle="Web広告"
      pagedesc="Web広告概要"
      pagepath={location.pathname}
    />
    <section className="area">
    <div className="container">
      <h2 className="bar">WEB広告</h2>
      <br />
      <br />
      <br />
      <br />
      <h2>Coming  Soon...</h2>
      <br />
      <h2>Please wait for a little while</h2>
      <br />
      <br />
      <br />
      <h3>ただいま準備中です！
      <br />
      近日公開予定
      </h3>
      <br />
      <br />
      <br />
      <br />
    </div>
    </section>
    <section className="Advertising">
    <div className="container">
    <div className="block">
        <div className="ad-detail">
        <Link to={`/cat/web_cat/`}>
        <button className="button-first">WEB広告一覧へ</button>
        </Link>
        </div>
        <div className="ad-detail">
        <Link to={`/form/`}>
        <button className="button-second">お問い合わせ</button>
        </Link>
        </div>
    </div>
    </div>
    </section>
  </Layout>
)

export default Web